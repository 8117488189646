import { checkEmailValidity, checkEmptyInput, checkTelephoneValidity } from "./functions/check-form.js";

// Gestion du menu dropdown
const services = document.getElementById("services");
const servicesDropdown = document.getElementById("services-dropdown");

/* When the user clicks on the button, toggle between hiding and showing the dropdown content */
services.addEventListener('click', (event) => {
    event.preventDefault();
    servicesDropdown.classList.toggle("show");
});

// Close the dropdown if the user clicks outside of it
window.onclick = function(event) {
    if (!event.target.matches('.dropdown__link')) {
        let dropdowns = document.getElementsByClassName("dropdown__list");
        let i;
        for (i = 0; i < dropdowns.length; i++) {
            let openDropdown = dropdowns[i];
            if (openDropdown.classList.contains('show')) {
                openDropdown.classList.remove('show');
            }
        }
    }
}


// Gestion de la modale (menu mobile)
const modal = document.getElementById('modal')
const burgerIcon = document.querySelector('.js-open-modal')
const closeIcon = document.querySelector('.js-close-modal')
let isVisible = false
const focusableSelector = '.js-close-modal, a'
let focusableElements = []

const openModal = function(e){
    e.preventDefault()
    modal.classList.add('show')
    modal.setAttribute('aria-hidden', 'false')
    modal.setAttribute('aria-modal', 'true')
    isVisible = true
    closeIcon.addEventListener('click', closeModal)
    window.addEventListener('keydown', listenEscape)
    document.querySelectorAll('.js-modal-link')
       .forEach(link => link.addEventListener('click', closeModal))
    window.addEventListener('click',clickOutForClose)
}

const closeModal = function(e){
    setTimeout(() => {
        modal.classList.remove('show')
        isVisible = false
    }, 400)
    modal.setAttribute('aria-hidden', 'true')
    modal.removeAttribute('aria-modal')

    closeIcon.removeEventListener('click', closeModal)
    window.removeEventListener('keydown', listenEscape)
    document.querySelectorAll('.js-modal-link')
       .forEach(link => link.removeEventListener('click', closeModal))
    window.removeEventListener('click', clickOutForClose)
}

const listenEscape = function(e){
    if (e.key === "Escape" || e.key === "Esc"){
        closeModal(e)
        window.removeEventListener('keydown', listenEscape)
    }
}

const clickOutForClose = function(e){
    if (isVisible === true && e.target === modal){
        closeModal(e)
    }
}

burgerIcon.addEventListener('click', openModal)

window.addEventListener('keydown', (e) => {
    if (burgerIcon.matches(':focus') && e.key === 'Enter'){
        openModal(e)
        document.querySelector('.js-modal-link').focus()
    }   
    if (closeIcon.matches(':focus') && e.key === 'Enter'){
        closeModal(e)
    }

    if (isVisible === true && e.key === 'Tab'){
        e.preventDefault()
        focusableElements = Array.from(modal.querySelectorAll(focusableSelector))
        let index = focusableElements.findIndex(f => f === modal.querySelector(':focus'))
        if (e.shiftKey === true){
            index--
        } else {
            index++
        }
        if (index >= focusableElements.length){
            index = 0
        }
        if (index < 0){
            index = focusableElements.length - 1
        }
        focusableElements[index].focus()
    }
})


// Masquer la nav au scrolldown
const headerBands = document.querySelector('.header__bands') //header__bands contient header__contact--mobile + js-header-nav
const headerNav = document.querySelector('.header__nav')
const headerContact = document.querySelector('.header__contact--mobile')

let lastScroll = 0

/**
 * Appliquer un délai à l'éxécution de la fonction callback
 * @param {function} callback
 * @param {number} delay 
 * @returns {function}
 */
const debounce = function(callback, delay){
    let timer
    return function test (){
        let args = arguments
        let context = this
        clearTimeout(timer);
        timer = setTimeout(function(){
            callback.apply(context, args)
        }, delay)
    }
}

let windowWidth = window.innerWidth
let headerNavHeight = parseInt(window.getComputedStyle(headerNav).height)
let headerContactHeight = parseInt(window.getComputedStyle(headerContact).height)
let headerBandsHeight = headerNavHeight + headerContactHeight

// récupérer la hauteur de la nav qui peut changer selon la largeur de la fenêtre (@mediaquery)
window.addEventListener('resize', debounce(function(){
    if (windowWidth !== window.innerWidth){
        headerNavHeight = parseInt(window.getComputedStyle(headerNav).height)
        headerContactHeight = parseInt(window.getComputedStyle(headerContact).height)
        headerBandsHeight = headerNavHeight + headerContactHeight
    }
    }, 500)
)


window.addEventListener('scroll', () => {
    let currentScroll = window.scrollY
    if(currentScroll < headerBandsHeight){
        return
    }
    if (currentScroll <= 0){
        headerBands.classList.remove('scroll-up')
        return
    }
    if (currentScroll > lastScroll){
        servicesDropdown.classList.remove("show")
        headerBands.classList.add('scroll-down')
        headerBands.classList.remove('scroll-up')
    } 
    if (currentScroll < lastScroll){
        headerBands.classList.add('scroll-up')
        headerBands.classList.remove('scroll-down')
    }
    lastScroll = currentScroll
}, {passive : true})



// Gestion validation formulaire de contact

const form = document.querySelector('.form__contact')
const btnSubmit = form.querySelector('.form__contact__btn')
const inputs = form.querySelectorAll('.form__contact__items input')
const emailInput = form.querySelector('.form__contact__items #email')
const telephoneInput = form.querySelector('.form__contact__items #telephone')


// Vérification des champs au submit
btnSubmit.addEventListener('click', (e) => {
    /*Supprime les messages d'erreurs si déja affichés */
    const errorElements = document.querySelectorAll('.error__message')
    errorElements.forEach(divError => {
        divError.classList.remove('show')
        const input = divError.parentElement.querySelector('input')
        input.classList.remove('not-valid')
        input.parentElement.parentElement.classList.remove('form-not-valid')
    })
    let errors = []
    /*Vérifie si les inputs sont vides */
    errors.push(checkEmptyInput(inputs))
    const isValidEmail = checkEmailValidity(emailInput)
    /* si champ email n'est pas vide mais invalide */
    if (isValidEmail === false){
        /**
             * @type {Object} dataError
             * @property {HTMLElement} element
             * @property {String} errorMessage
             */
        const dataError = {
            element : emailInput,
            errorMessage : "L'email n'est pas valide"
        }
        errors.push(dataError)
    }
    const isValidTelephone = checkTelephoneValidity(telephoneInput)
    /* si champ téléphone n'est pas vide mais invalide */
    if (isValidTelephone === false){
        const dataError = {
            element : telephoneInput,
            errorMessage : "Le numéro de téléphone n'est pas valide"
        }
        errors.push(dataError)
    }
    errors = errors.flat(1)
    if (errors.length !== 0){
        e.preventDefault()
        for (let error of errors){
            const {element, errorMessage} = error
            element.classList.add('not-valid')
            element.parentElement.parentElement.classList.add('form-not-valid')
            const divError = element.parentElement.querySelector('.error__message')
            divError.querySelector('span').innerText = errorMessage
            divError.classList.add('show')
        }
    }
})

// reset formulaire après la soumission via Formspree
window.onbeforeunload = () => {
    for(const form of document.querySelector('.form__contact')) {
      form.reset();
    }
  }