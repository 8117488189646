/**
 * Vérifie si les inputs sont vides
 * @param {Array<HTMLInputElement>} inputElements
 * @return {Array<Object>} errors
 */
export function checkEmptyInput(inputElements){
    let errors = []
    for (let element of inputElements){
        const value = element.value.trim()
        if (value === ""){
            /**
             * @type {Object} dataError
             * @property {HTMLElement} element
             * @property {String} errorMessage
             */
            const dataError = {
                element : element,
                errorMessage : "Ce champ ne peut être vide"
            }
            errors.push(dataError)
        }
    }
    return errors
}

/**
 * Vérifie la validité du champ email avec une regex
 * @param {HTMLInputElement} emailInput 
 * @returns {Boolean | undefined} undefined si champ vide, true si champ valide, false si invalide
 */
export function checkEmailValidity(emailInput){
    const input = emailInput
    /*Si input vide, la fonction checkEmptyInput() affichera l'erreur pour le champ email*/
    if (input.value.trim() === ""){
        return
    }
    const regexEmail = new RegExp("^[a-zA-Z0-9.!#$%&\'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$")
    const isValid = regexEmail.test(input.value.trim())
    if (isValid === true){
        return true
    } else {
        return false
    }
}

/**
 * Vérifie la validité du champ téléphone avec une regex
 * @param {HTMLInputElement} telephoneInput 
 * @returns {Boolean | undefined} undefined si champ vide, true si champ valide, false si invalide
 */

export function checkTelephoneValidity(telephoneInput){
    const input = telephoneInput
    /*Si input vide, la fonction checkEmptyInput() affichera l'erreur pour le champ telephone*/
    if (input.value.trim() === ""){
        return
    }
    const regexTelephone = new RegExp("^[0-9+\-/ ()]*[0-9][0-9+\-/ ()]*$")
    const isValid = regexTelephone.test(input.value.trim())    
    if (isValid === true){
        return true
    } else {
        return false
    }
}